import React from "react"
import CurrentRequest from "../components/CurrentRequest"
import RequestHistory from "../components/RequestHistory"
import UploadRequest from "../components/UploadRequest"
import { Box, Tabs, Tab } from "@mui/material"
import TabPanel from "../components/TabPanel"

const Requests = ({ location }) => {
    const { state = {} } = location
    const { tabIndex } = state || {}

    const [value, setValue] = React.useState(tabIndex === undefined ? 0 : tabIndex)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const a11yProps = index => {
        return {
            id: `requests-tab-${index}`,
            "aria-controls": `requests-tabpanel-${index}`,
        }
    }

    return (
        <>
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Новый запрос" {...a11yProps(0)} />
                        <Tab label="Загрузить запрос" {...a11yProps(1)} />
                        <Tab label="Запросы в работе" {...a11yProps(2)} />
                        <Tab label="Архив запросов" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} aria="requests">
                    <CurrentRequest />
                </TabPanel>
                <TabPanel value={value} index={1} aria="requests">
                    <UploadRequest />
                </TabPanel>
                <TabPanel value={value} index={2} aria="requests">
                    <RequestHistory />
                </TabPanel>
                <TabPanel value={value} index={3} aria="requests">
                    <RequestHistory status={"В архиве"} />
                </TabPanel>
            </Box>
        </>
    )
}
export default Requests
